<template>
  <div class="bg-white py-md-2 py-2 px-md-4 px-3  mb-3 top-header-service">
    <div class="content-wrapper d-md-flex justify-content-between px-3">
      <div>
        <div class="d-flex align-items-center mb-2">
          <router-link class="pointer h5 m-0" :to="`/homologation/${$route.params.homologation_id}/service/list`">
            <b-icon icon="arrow-left" class="mr-2" />
          </router-link>
          <skeleton v-if="!serviceScope.scope" height="24px" :rows="1" grid="1" gap="10" />
          <section v-else>
            <span class="d-block text-muted">Serviço</span>
            <h5 class="mb-2">{{ serviceScope.scope }}</h5>
            <span class="d-block text-muted">Tomador</span>
            <p class="m-0">{{ serviceScope.company_name }}</p>
          </section>
        </div>

        <div class="mt-2 text-muted d-flex align-items-center">
          <b-icon icon="calendar3" />
          <span class="mx-2">{{ serviceScope.date_init | dateFull }}</span> à
          <span class="mx-2"> {{ serviceScope.date_end | dateFull }}</span>
        </div>
        <div class="mt-2 text-muted d-flex align-items-center">
          <b-icon icon="cash" />
          <span class="mx-2">
            Valor total deste contrato:
            {{ serviceScope.contract_value_total | money }}
          </span>
        </div>
      </div>

      <b-icon v-if="is_mobile" style="right: 23px; top: 12px" class="position-absolute h4"
        v-b-toggle.sideba-details-service icon="info-circle" />
      <div v-else>
        <div v-permission="'service.view_service_notifications'" class="mt-md-0 mt-4">
          <strong>Convidados</strong>
          <div class="d-flex gap-2">
            <template v-if="serviceScope.notifications">
              <avatar bordered no-shadow v-for="(notification,
                index) in serviceScope.notifications.slice(0, 4)" :key="index" :name="notification.email" />
            </template>
            <avatar bordered no-shadow v-if="sliceAvatar() > 0" more
              :name="`+${serviceScope.notifications.length - sliceAvatar()}`" />
          </div>
        </div>

        <b-button class="mt-3 text-primary pl-0 pl-md-2" size="sm" variant="light" v-b-toggle.sideba-details-service>
          Mais detalhes do serviço
        </b-button>
      </div>
      <div v-if="!is_provider" class="align-self-end">
        <b-button @click="handleFinishService()" variant="primary" size="sm" :disabled="serviceScope.pending_provider">
          Finalizar Homologação
        </b-button>
      </div>
    </div>
    <b-sidebar id="sideba-details-service" size="lg" right shadow backdrop width="50%">
      <service-detail :service_scope="serviceScope" />
    </b-sidebar>
    <b-modal hide-footer hide-header body-class="min-h-40" size="md" centered no-close-on-backdrop no-close-on-esc
      id="finish-service-validation">
      <h4 class="text-center my-3">Finalizar Homologação</h4>
      <img src="@/assets/images/svg/winner.svg" alt="" width="100%" />
      <strong class="d-block">
        Atenção você está finalizando o processo de homologação deste serviço.
        O sistema irá definir automaticamente o ganhador de acordo com a pontuação
        atual dos prestadores. Essa ação não
        poderá ser revertida
      </strong>
      <div class="mt-4">
        <b-button :disabled="inProgress" block variant="primary" @click="start()">
          Quero continuar
        </b-button>
        <b-button block variant="link" @click="cancel()">Voltar</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ServiceDetail from "./ServiceDetail.vue";

export default {
  components: {
    ServiceDetail,
  },
  props: {
    back: {
      type: String,
    },
  },
  data() {
    return {
      serviceScope: {
        notifications: [],
      },
      inProgress: false
    };
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  methods: {
    ...mapActions(["hide_application_header", "get_service_scope", "finish_service_homologation"]),
    handleFinishService() {
      this.$bvModal.show("finish-service-validation");
    },
    cancel() {
      this.$bvModal.hide("finish-service-validation");
    },
    start() {
      this.inProgress = true;
      this.finish_service_homologation({ service_id: this.service_id })
        .then(({ status }) => {
          if (status === 200) {
            this.$bvModal.hide("finish-service-validation");
            this.$router.go(this.$router.currentRoute)
          }

          this.inProgress = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
    sliceAvatar() {
      const length = this.serviceScope?.notifications?.length;
      if (length > 4) {
        return length - 4;
      }
      return 0;
    },
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.hide_application_header(false);
    this.get_service_scope({ id: this.service_id }).then((data) => {
      this.serviceScope = data;
    });
  },
};
</script>
